/****************************** components ******************************/
@import "./components/mixin";
@import "./components/default";
@import "./components/normalize";
@import "./components/reset";
@import "./components/grid";
//@import "./components/case";//例子
/****************************** style ******************************/

body,
button,
input,
select,
textarea {
    font: 12px/1.4 "\5FAE\8F6F\96C5\9ED1", "arial";
    color: #5a5a5a;
    font-size: 12px;
}

.center {
    width: 1200px;
    margin: 0 auto;
}

.header {
    width: 100%;
    overflow: hidden;
}
.head_text {
    height: 35px;
    background: #F6F6F6;
    p {
        line-height: 35px;
        width: 100%;
        b {
            font-size: 14px;
        }
        span {
            float: right;
            a {
                font-size: 14px;
                margin-right: 4px;
            }
        }
    }
}
.head_top_content {
    width: 100%;
    height: 96px;
    overflow: hidden;
    .head_logo {
        img {
            height: 82px;
            padding-top: 7px;
            float: left;
        }
        div {
            float: left;
            padding-left: 10px;
            padding-top: 10px;
            h4 {
                font-size: 31px;
                letter-spacing: 4px;
                color: #000;
            }
            h6 {
                font-size: 17px;
                line-height: 140%;
                color: #000;
            }
        }
    }
    .fr {
        height: 96px;
        padding-top: 20px;
        img {
            height: 70%;
            float: left;
            margin-right: 5px;
        }
        .img4 {
            height: 80%;
        }
        p {
            float: left;
            padding-top: 10px;
            i {
                display: block;
                font-size: 14px;
                line-height: 140%;
            }
            span {
                display: block;
                color: #0B89DD;
                font-size: 17px;
                font-weight: bold;
            }
        }
    }
}
.head_nav {
    width: 100%;
    background: #0B89DD;
    height: 42px;
    ul {
        
        li {
            float: left;
            width: 121px;
            line-height: 42px;
            text-align: center;
            &:first-child {
                background: url(../img/lil.png) no-repeat left center;
            }
            &:hover a{
                background: url(../img/li.jpg) repeat-x;
                color: #fff;
            }
            a{
                width: 121px;
                height: 42px;
                font-size: 14px;
                color: #fff;
                font-weight: bold;
                display: block;
                background: url(../img/lil.png) no-repeat right center;
            }
        }
    }
}
.banner {
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 430px;
    ul {
        li {
            position: relative;
            img{
                width: 1920px;
                height: 430px;
            }
        }
    }
}
.banner_text {
    position: absolute;
    left: 19%;
    top: 25%;
    z-index: 99;
    .banner_text_content {
        p {
            strong {
                color: #034EA2;
                font-size: 51px;
                display: block;
            }
            span {
                display: block;
                letter-spacing: 2px;
                color: #034EA2;
                font-size: 32px;
                padding-left: 160px;
                font-weight: bold;
            }
        }        
    }
}
.main {
    width: 100%;
} 
.i_box1 {
    width: 100%;
    padding-bottom: 40px;
    .i_box1_content {
        width: 100%;
        .main_r {
            margin-top: 0;
        }
    }
}
.search {
    width: 100%;
    height: 43px;
    overflow: hidden;
    margin-bottom: 25px;
    p {
        float: left;
        line-height: 43px;
        b {
            font-weight: bold;
            font-size: 14px;
        }
        a {
            font-size: 14px;
            margin-left: 4px;
            &:hover {
                color: #47cdff;
            }
        }
    }
    div {
        float: right;
        padding-top: 10px;
        .texta {
            width: 256px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            color: #666666;
            font-weight: normal;
            line-height: 24px;
            height: 28px;
            vertical-align: middle;
            border: #0b89dd 2px solid;
            padding-left: 5px;
            padding-right: 5px;
            -webkit-appearance: none;
            outline: none;
            -webkit-border-radius: 0;
            display: block;
            float: left;
        }
        .btna {
            width: 50px;
            background: #0b89dd;
            font-family: SimSun;
            font-weight: normal;
            border: #fff 0px solid;
            float: left;
            height: 28px;
            line-height: 28px;
            cursor: pointer;
            color: #fff;
            font-size: 14px;
            text-align: center;
            float: left;
        }
    }
}
.main_l {
    width: 222px;
    position: relative;
    z-index: 999;
    p {
        background: #0B89DD;
        width: 100%;
        height: 57px;
        padding-left: 32px;
        strong {
            float: left;
            font-style: italic;
            font-size: 53px;
            color: #fff;
            line-height: 57px;
            padding-right: 20px;
        }
        span {
            font-size: 22px;
            padding-top: 4px;
            color: #fff;
            font-weight: bold;
            display: block;
        }
        i {
            font-size: 21px;
            font-style: normal;
            display: block;
            color: #fff;
            letter-spacing: 5px;
            line-height: 18px;
        }
    }
    ul {
        width: 100%;
        background: #F6F6F6;
        padding: 10px 15px;
        li {
            width: 100%;
            background: url(../img/lia.png) no-repeat;
            height: 49px;
            margin-bottom: 3px;
            &:hover {
                background: url(../img/lib.png) no-repeat;
                span {
                    color: #fff;
                }
            }
            a {
                height: 48px;
                width: 100%;
                display: block;
                span {
                    font-size: 12px;
                    color: #fff;
                    line-height: 49px;
                    display: block;
                    text-align: center;
                }
                i {
                    float: right;
                    font-style: normal;

                }
            }
        }
    }
    .contact {
        width: 100%;
        padding-top: 40px;
        border: 1px solid #eee;
        img {
            width: 100%;
            display: block;
        }
        span {
            line-height: 145%;
            letter-spacing: 1px;
            font-size: 13px;
            display: block;
            padding: 10px;
        }
    }
}
.main_r {
    width: 931px;
    float: right;
}
.pro_list {
    width: 931px;
    overflow: hidden;
    margin-top: 32px;
    ul {
        width: 106%;
        li {
            width: 217px;
            height: 219px;
            padding: 15px;
            margin-right: 21px;
            margin-bottom: 17px;
            float: left;
            border: 1px solid #eee;
            &:hover {
                border: 1px solid #FF6600;
                span {
                    color: #0B89DD;
                }
            }
            a {
                .imgauto1 {
                    width: 185px;
                    height: 150px;
                    overflow: hidden;
                }
                img {
                    
                }
                span {
                    display: block;
                    margin-top: 6px;
                    text-align: center;
                    font-size: 14px;
                }
            }
        }
    }
}
.i_box2 {
    width: 100%;
    height: 235px;
    overflow: hidden;
    background: url(../img/abbg.jpg);
    .i_box2_content {
        .fl {
            width: 680px;
            padding-top: 40px;
            padding-left: 30px;
            .i_box2_top {
                width: 100%;
                float: left;
                p {
                    height: 48px;
                    line-height: 48px;
                    letter-spacing: 1px;
                    font-size: 25px;
                    font-weight: bold;
                    margin-right: 20px;
                    float: left;
                    color: #fff;
                    background: url(../img/gou.png) no-repeat left center;
                    padding-left: 54px;
                }
            }
            .i_box2_text {
                margin-top: 30px;
                width: 100%;
                float: left;
                p {
                    color: #fff;
                    line-height: 200%;
                    font-size: 14px;
                    a {
                        color: #FF0000;
                    }
                }
            }
        }
        .fr {
            width: 406px;
            img {
                width: 359px;
                border: 8px solid #fff;
                height: 196px;
                margin-top: 20px;
            }
        }
    }
}
.i_box3 {
    width: 100%;
    overflow: hidden;
    padding-top: 15px;
    overflow: hidden;
    .i_box3_tit {
        width: 100%;
        border-bottom: 1px solid #eee;
        p {
            display: block;
            font-size: 22px;
            line-height: 30.8px;
            color: #0B89DD;
            text-align: center;
            font-weight: bold;
            padding-bottom: 10px;
            span {
                padding-bottom: 10px;
                border-bottom: 3px solid #eee;
            }
        }
    }
    .i_box3_content {
        width: 100%;
        margin-top: 50px;
        overflow: hidden;
        ul {
            li {
                width: 230px;
                padding: 0 15px;
                float: left;
                a {
                    img {
                        width: 200px;
                        height: 160px;
                    }
                    p {
                        padding-top: 25px;
                        text-align: center;
                        &:hover {
                            color: #0B89DD;
                        }
                    }
                }
            }
        }
    }
}
.imgauto1 {
    width: 185px;
    height: 150px;
    overflow: hidden;
}
.i_box4_content {
    width: 100%;
    padding-top: 25px;
    overflow: hidden;
    .new_list {
        width: 539px;
        .i_box4_tit {
            width: 100%;
            padding: 10px 0;
            font-size: 12px;
            height: 45px;
            border-bottom: 1px solid #eee;
            float: left;
            p {
                float: left;
                strong {
                    line-height: 140%;
                    color: #333;
                    font-size: 18px;
                }
            } 
            a {
                float: right;
                line-height: 25px;
                color: #0B89DD;
                font-size: 12px;
            }
        }
        ul {
            li {
                width: 100%;
                float: left;
                padding: 12px 0;
                border-bottom: 1px solid #dbd4cd;
                margin: 10px 0;
               .i_box4_time {
                    width: 60px;
                    height: 59px;
                    background: #198ede;
                    margin-left: 20px;
                    p {
                        span {
                            font-size: 24px;
                            color: #fff;
                            text-align: center;
                            font-weight: bold;
                            line-height: 41px;
                            display: block;
                        }
                        i {
                            font-style: normal;
                            text-align: center;
                            color: #fff;
                            font-size: 13px;
                            display: block;
                            line-height: 18px;
                        }
                    }
                }
                .i_box4_text {
                    width: 409px;
                    margin-left: 40px;
                    a {
                        span {
                            font-size: 19px;
                            color: #5A5A5A;
                            line-height: 140%;
                            &:hover {
                                color: #0064D4;
                            }
                        }
                    }
                    p {
                        font-size: 12px;
                        color: #5A5A5A;
                        line-height: 140%;
                    }
                }
            }
        }
    }
}
.footer {
    width: 100%;
    height: 183px;
    margin-top: 40px;
    background: #198ede;
    .foot_menu {
        height: 64px;
        width: 100%;
        padding-top: 40px;
        ul {
            width: 100%;
            text-align: center;
            li {
                height: 20px;
                width: 112px;
                @include inline-block();
                border-left: 1px solid #f2f2f2;
                &:first-child {
                    border-left: none;
                }
                a {
                    color: #fff;
                    width: 112px;
                    height: 20px;
                    line-height: 20px;
                    display: block;
                    text-align: center;
                }
            }
        }
    }
    .foot_text {
        width: 100%;
        p {
            font-size: 13px;
            text-align: center;
            color: #fff;
            line-height: 17px;
            margin-top: 30px;
            span {
                margin-left: 25px;
            }
        }
    }   
}
.page_banner {
    width: 100%;
    overflow: hidden;
    height: 350px;
    img {
        width: 1920px;
        position: relative;
        left: 50%;
        margin-left: -960px;
    }
}
.container {
    width: 100%;
    margin-top: 12px;
    .main_r {
        width: 924px;
    }
}
.crumbs {
    width: 924px;
    height: 29px;
    padding-bottom: 4px;
    border-bottom: 1px solid #0B89DD;
    span {
        float: left;
        font-size: 16px;
        border-bottom: 4px solid #0B89DD;
        padding-bottom: 4px;
        padding-right: 15px;
    }
    p {
        float: right;
        height: 29px;
        padding-left: 20px;
        b {
            font-size: 14px;
            color: #333;
            font-weight: normal;
        }
        a {
            font-size: 14px;
            color: #333;
        }
    }
}
.news_list {
    width: 905px;
    height: 573px;
    padding: 20px 0;
    ul {
        li {
            width: 100%;
            padding-top: 9px;
            border-bottom: 1px solid #EBEBEB;
            margin-bottom: 8px;
            a {
                display: block;
                width: 100%;
                margin-bottom: 5px;
                span {
                    font-size: 14px;
                    font-weight: bold;
                    &:hover {
                        color: #0B89DD;
                    }
                }
            }
            b {
                display: block;
                columns: #929292;
                font-size: 11px;
                font-weight: normal;
                margin-bottom: 5px;
                line-height: 140%;
            }
            p {
                display: block;
                font-size: 12px;
                margin-bottom: 5px;
            }
        }
    }
}
.pages {
    width: 100%;
    text-align: center;
    a {
        padding: 2px 5px;
        border: 1px solid #198ede;
        color: #198ede;
        font-size: 12px;
        margin: 0 2px;
        @include inline-block();
        &:hover {
            background: #198ede;
            color: #fff;
        }
        &.active {
            background: #198ede;
            color: #fff;
        }
    }
}
.content_tit {
    width: 100%;
    margin-top: 30px;
    h3 {
        font-size: 16px;
        color: #0b89dd;
        padding: 3px 0;
        line-height: 1.7em;
        text-align: center;
        font-weight: bold;
    }
    p {
        width: 100%;
        text-align: center;
        padding: 10px 0 15px;
        span {
            line-height: 21px;
            font-size: 12px;
            color: #666;
            margin: 0 5px;
        }
    }
}
.content_des {
    width: 100%;
    border: 1px dotted #E5E5E5;
    padding: 5px 0;
    margin-bottom: 10px;
    text-indent: 24px;
    background: #F5F5F5;
    p {
        font-size: 13px;
        color: #868686;
        line-height: 24px;
    }
}
.content {
    p {
        font-size: 14px;
        line-height: 24px;
        text-indent: 2em;
        margin-top: 15px;
    }
}
.prve {
    width: 100%;
    margin-top: 20px;
    p {
        height: 25px;
        width: 100%;
        a {
            width: 100%;
            line-height: 25px;
            font-size: 14px;
            color: #5a5a5a;
            &:hover {
                color: #1c4587;
            }
        }
    }
}
.content_top {
    width: 100%;
    margin-top: 40px;
    .content_img {
        width: 302px;
        height: 302px;
        margin-bottom: 21px;
        margin-left: 12px;
        float: left;
        margin-right: 30px;
        position: relative;
        border: 1px solid #eee;
        .imgauto {
            width: 300px;
            height: 300px;
            overflow: hidden;
            img {
                display: block;
                &::selection { background-color: transparent; }
                &:hover { cursor: url(../img/grab.cur), default; }
                &:active { cursor: url(../img/grabbed.cur), default; }
            }

        }
    }
    .content_price {
        width: 555px;
        float: left;
        h3 {
            font-size: 20px;
            font-weight: normal;
            line-height: 33px;
            margin-bottom: 10px;
            border-bottom: 1px solid #E2E2E2;
        }
        p {
            font-size: 14px;
            margin-bottom: 10px;
            span {
                font-size: 14px;
                text-decoration: line-through;
                color: #898989;
            }
            strong {
                color: #FF5022;
                font-size: 20px;
            }
        }
    }
}
.container {
    .pro_list {
        width: 931px;
        li {
            margin-right: 21px;
        }
    }
}
.show_img {
    img {
        width: 50%;
    }
}
.show_guest {
    float: right;
    margin-top: 50px;
    width: 100%;
    .fl {
        width: 353px;
        height: 458px;
        padding: 0 20px;
        position: relative;
        i {
            width: 1px;
            font-style: normal;
            display: block;
            height: 458px;
            background: #eee;
            position: absolute;
            left: 175px;
            top: 0;
        }
        .guestbook {
            width: 313px;
            height: 148px;
            padding: 15px 0;
            background: #fff;
            position: relative;
            z-index: 99;
            top: 155px;
            b {
                width: 313px;
                height: 118px;
                overflow: hidden;
                display: block;
                position: absolute;
                img {
                    width: 313px;
                    position: absolute;
                    top: -70px;
                }
            }
            p{
                position: absolute;
                width: 313px;
                height: 118px;
                top: 15px;
                left: 0;
                padding-top: 26px;
                strong {
                    font-size: 29px;
                    line-height: 43px;
                    text-align: center;
                    display: block;
                    font-weight: normal;
                    color: #fff;
                }
                span {
                    color: #fff;
                    font-size: 12px;
                    line-height: 18px;
                    text-align: center;
                    display: block;
                }
            }
        }
    }
    .fr {
        width: 415px;
        height: 458px;
        padding: 20px;
        border: 1px solid #ECECEC;
        p {
            width: 370px;
            float: left;
            margin: 7px 0;
            span {
                padding: 10px 0;
                line-height: 1.5;
                font-size: 12px;
                display: block;
            }
            input {
                width: 370px;
                height: 37px;
                border: none;
                border: 1px solid #ddd;
                font-size: 12px;
                text-indent: 5px;
            }
            textarea {
                width: 370px;
                height: 162px;
                border: none;
                border: 1px solid #ddd;
                font-size: 12px;
                text-indent: 5px; 
            }
            .btn {
                height: 49px;
                border: none;
                border-radius: 4px;
                background: #0269ab;
                color: #fff;
                &:hover {
                    background: #00aff1;
                }
            }
        }
    }
}
.kefu {
    position: fixed;
    right: 0;
    top: 20%;
    width: 53px;
    height: 90px;
    z-index: 99;
    transition: 1s;
    p {
        width: 53px;
        height: 90px;
        position: absolute;
        right: 0;
        transition: 1s;
        img {
            position: absolute;
            right: 0;
        }
        span {
            position: absolute;
            right: 6px;
            top: 55px;
            color: #fff;
            font-size: 14px;
        }
    }
    &:hover {
        right: -60px;
        transition: 1s;
    }
    &:hover .float_img {
        right: 0;
        transition: 1s;
    }
}
.float_img {
    position: fixed;
    right: -200px;
    top: 20%;
    width: 191px;
    height: 400px;
    z-index: 99;
    transition: 1s;
    .qq {
        background: url(../img/floa.png) no-repeat center;
        width: 191px;
        height: 400px;
        padding-top: 130px;
        padding-left: 70px;
        position: absolute;
        right: 0;
        top: -20px;
        transition: 1s;
        span {
            position: absolute;
            left: 25px;
            width: 30px;
            color: #fff;
            font-size: 14px;
            top: 45%;
        }
        a {
            background: url(../img/qqa.png) no-repeat 4px center;
            height: 25px;
            line-height: 25px;
            width: 84px;
            padding: 0 4px;
            display: block;
            margin: 5px 5px 10px 5px;
            padding-left: 30px;
            border-radius: 4px;
            &:hover {
                background: url(../img/qq.png) no-repeat 4px center;
                color: #fff;
                background-color: #1098FF;
            }
        }
    }
}
.scrolltop {
    width: 41px;
    height: 45px;
    position: fixed;
    bottom: 10%;
    right: 5%;
    p {
        width: 41px;
        height: 45px;
        cursor: pointer;
        background: url(../img/ttpa.png) no-repeat center;
    }
}
/****************************** media ******************************/